import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const markdown = props.data.allMdx.edges;
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO
      title="Gizmos - Custom Car Builder"
      description="Award winning, custom cars, muscle cars, racecars, and hot rods"
      />
      <div className="intro pb-4">
        <div className="container">
          <h1>Welcome To Gizmos</h1>
          <p>
            We build custom hot rods, pro-touring cars, muscle cars, race cars, land speed cars, and handle tons of car restoration. Let's talk about your project.
          </p>
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-light mb-3">Contact Gizmos About...</h2>
          </div>
          {markdown.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                  <Link to="/custom-car-build-contact">{edge.node.frontmatter.title}</Link>
                  </h2>
                  {/* <p>{edge.node.excerpt}</p> */}
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/custom-car-build-contact">
              Need Something Else? Let's hear about it.
            </Link>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-light mb-4">Learn More About Gizmos Custom Car Shop</h2>
          </div>
          {json.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
              <div className="feature">
              <Link to={edge.node.url}>
                {edge.node.image && (
                  <div className="feature-image">
                    <img src={withPrefix(edge.node.image)} />
                  </div>
                )}
                <h2 className="feature-title">{edge.node.title}</h2>
                <div className="feature-content">{edge.node.description}</div>
              </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="mb-5"><h2>The custom car you've always wanted.</h2></div>
        <div className="row">
          <div className="col-sm">Gizmos is a Minnesota-based full service custom car and hot rod shop. Whether it’s creating your ground-up, one-off custom dream machine, tweaking your classic ride or making your hot rod hotter, you can be assured of one thing: When Gizmos builds your car it will be as beautiful as it is functional.</div>
          <div className="col-sm">Since 2003 the Gizmos crew has been setting the bar ever higher in the local and national custom car and hot rod scene. Multiple Wally Awards, an NHRA Championship, Best in Show, Best in Category and countless other outstanding awards are testament to the care and quality that go into every Gizmos car.</div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
          url
        }
      }
    }
  }
`;

export default Home;
